/**
 * @param {ReadableStream} stream
 * @return {AsyncGenerator<Promise<any>, Promise<void>>}
 */
export async function* streamReader(stream) {
  const reader = stream.getReader();
  let eos = false;
  do {
    const { done, value } = await reader.read();
    eos = done;
    if (!eos) {
      yield Promise.resolve(value);
    }
  } while (!eos);

  return Promise.resolve();
}

/**
 * @param {ReadableStream} stream
 * @param {Number} length
 * @return {AsyncGenerator<Promise<any>, Promise<void>>}
 */
export async function* streamReaderWithLength(stream, length = 10) {
  let eos = false;
  const reader = stream.getReader();

  for (let i = 0; i < length && !eos; i++) {
    const { done, value } = await reader.read();
    eos = done;
    if (!eos) {
      yield Promise.resolve(value);
    } else {
      yield Promise.resolve({ done: true });
    }
  }

  reader.releaseLock();
  return Promise.resolve();
}
