const cleanPath = (path) => {
  let cleanPath = path;
  if (cleanPath !== '/') {
    cleanPath = cleanPath.replace(/\/$/, '');
  }
  return cleanPath;
};

const homeItem = { to: '/', text: 'breadcrumbs./' };

export const state = () => ({
  items: [],
  history: {},
});

export const getters = {
  items(state) {
    return state.items;
  },
  lastItem(state) {
    const l = state.items.length - 1;
    if (state.items[l]) {
      return state.items[l];
    }
  },
};

export const mutations = {
  set(state, items) {
    state.items = items;
  },
  empty(state) {
    state.items = [];
  },
  syncHistory(state, { path }) {
    state.history[path] = state.items;
  },
  pushItem(state, { to, text }) {
    const l = state.items.length - 1;
    if (!state.items[l]) {
      state.items.push({ disabled: true, to, text });
      return;
    }

    if (state.items[l].to === to) return;
    state.items[l].disabled = false;
    state.items.push({ disabled: true, to, text });
  },
};

export const actions = {
  generate({ commit, state, getters }, { route }) {
    const path = cleanPath(route.path);
    if (/^\/contact/.test(path) || ['/', '/article', '/articles'].includes(path)) {
      return;
    }
    commit('empty');
    const fullPath = cleanPath(route.fullPath);

    if (state.history[fullPath]) {
      commit('set', state.history[fullPath]);
      return;
    }

    const routeItems = path.split('/');
    routeItems.shift();
    commit('pushItem', { ...homeItem });

    let to = '';
    for (const routeItem of routeItems) {
      to += `/${routeItem}`;
      const text = `breadcrumbs.${to}`;
      const item = { to, text };
      commit('pushItem', item);
    }

    commit('syncHistory', { path: fullPath });
  },
};
