import { Author as AuthorDAO } from '@opalenet/technodrome/src/dao/daos';
import { streamReader } from '~/utils/content';

export const state = () => ({
  authors: {},
  dao: undefined,
});

export const getters = {
  authors(state) {
    return state.authors;
  },
  author: (state) => (uid) => {
    return state.authors[uid];
  },
};

export const mutations = {
  setDao(state, dao) {
    if (dao instanceof AuthorDAO) {
      state.dao = dao;
    }
  },
  setAuthors(state, authors) {
    state.authors = authors;
  },
};

export const actions = {
  async fetchAuthors({ commit, state }) {
    const stream = state.dao.list();
    const reader = streamReader(stream);
    const authors = {};

    for await (const author of reader) {
      if (author) {
        authors[author.uid] = author;
      }
    }
    commit('setAuthors', authors);
  },
};
