import { merge } from '@opalenet/donatello/lib/utils/objects';
import about from './about.json';
import account from './account.json';
import article from './article.json';
import articles from './articles.json';
import breadcrumbs from './breadcrumbs.json';
import coaching from './coaching.json';
import contact from './contact.json';
import donatello from './donatello.json';
import error from './error.json';
import errors from './errors.json';
import footer from './footer.json';
import general from './general.json';
import home from './home.json';
import logout from './logout.json';
import registration from './registration.json';
import cashback from './cashback.json';

const messages = merge(
  {},
  about,
  account,
  article,
  articles,
  breadcrumbs,
  coaching,
  contact,
  donatello,
  error,
  errors,
  footer,
  general,
  home,
  logout,
  registration,
  cashback
);

export default messages;
