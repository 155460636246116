import { Tag as TagDAO } from '@opalenet/technodrome/src/dao/daos';
import { streamReader } from '~/utils/content';

export const state = () => ({
  tags: {},
  dao: undefined,
});

export const getters = {
  tags(state) {
    return state.tags;
  },
  tag: (state) => (uid) => {
    return state.tags[uid];
  },
};

export const mutations = {
  setDao(state, dao) {
    if (dao instanceof TagDAO) {
      state.dao = dao;
    }
  },
  setTags(state, tags) {
    state.tags = tags;
  },
};

export const actions = {
  async fetchTags({ commit, state }) {
    const stream = state.dao.list();
    const reader = streamReader(stream);
    const tags = {};

    for await (const tag of reader) {
      if (tag) {
        tags[tag.uid] = tag;
      }
    }
    commit('setTags', tags);
  },
};
