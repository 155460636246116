import { daoFactory, daoTypes } from '@opalenet/technodrome';

export const state = () => ({
  site: {
    name: 'Un-Lover',
    url: 'www.un-lover.com',
  },
  title: '',
  daoInitialized: false,
  bankingService: {
    name: 'www.supeuro.com',
    url: 'https://www.supeuro.com',
  },
});

export const getters = {
  siteInfo(state) {
    return state.site;
  },
  title(state) {
    return state.title;
  },
};

export const mutations = {
  initializeDao(state) {
    state.daoInitialized = true;
  },
  setTitle(state, title) {
    state.title = title;
  },
};

export const actions = {
  async initDAOs({ commit, state }, $config) {
    const config = { host: $config.APIBasePath };
    const authorDao = await daoFactory(daoTypes.author, config);
    const categoryDao = await daoFactory(daoTypes.category, config);
    const tagDao = await daoFactory(daoTypes.tag, config);
    const articleDao = await daoFactory(daoTypes.article, config);
    commit('author/setDao', authorDao);
    commit('category/setDao', categoryDao);
    commit('tag/setDao', tagDao);
    commit('article/setDao', articleDao);
    commit('initializeDao');
  },
  generateTitle({ commit }, { path }) {
    commit('setTitle', `breadcrumbs.${path !== '/' ? path.replace(/\/$/, '') : path}`);
  },
};
