
  import { mapGetters } from 'vuex';
  import AppDialog from '~/components/AppDialog.vue';

  export default {
    name: 'AppHeader',
    components: { AppDialog },
    data: () => {
      return {
        drawer: false,
        logoutModal: false,
      };
    },
    computed: {
      ...mapGetters('menu', ['getMenu']),
      ...mapGetters('auth', ['authenticated']),
      appBarMenu() {
        if (this.authenticated) {
          return this.$vuetify.breakpoint.smAndDown ? this.getMenu('memberMobile') : this.getMenu('member');
        } else {
          return this.$vuetify.breakpoint.smAndDown ? this.getMenu('publicMobile') : this.getMenu('public');
        }
      },
      drawerMenu() {
        return this.authenticated ? this.getMenu('memberDrawer') : this.getMenu('publicDrawer');
      },
    },
    methods: {
      toggleDrawer() {
        this.drawer = !this.drawer;
      },
      logout(confirm) {
        if (!confirm) {
          return;
        }
        this.$auth.disconnect();
        this.$router.push('/');
      },
      dispatchEvent(event) {
        if (event === 'logout') {
          this.drawer = false;
          this.logoutModal = true;
        }
      },
    },
  };
