import { Article as ArticleDAO } from '@opalenet/technodrome/src/dao/daos';
import Vue from 'vue';
import { streamReaderWithLength } from '~/utils/content';

export const state = () => ({
  articlesLists: {},
  queryUids: [],
  params: {},
  articles: {},
  dao: undefined,
});

export const getters = {
  article: (state) => (uid) => {
    return state.articles[uid];
  },
  articlesList: (state) => (queryUid) => {
    return state.articlesLists[queryUid];
  },
  queryUids(state) {
    return state.queryUids;
  },
  params: (state) => (queryUid) => {
    return state.params[queryUid];
  },
};

export const mutations = {
  setDao(state, dao) {
    if (dao instanceof ArticleDAO) {
      state.dao = dao;
    }
  },
  addArticles(state, articles) {
    Object.entries(articles).forEach(([key, value]) => {
      if (!state.articles[key]) {
        state.articles[key] = value;
      }
    });
  },
  addArticle(state, article) {
    state.articles[article.uid] = article;
  },
  setArticlesList(state, { articles, query, stream, end }) {
    const articlesUids = [];
    Object.entries(articles).forEach(([key, value]) => {
      articlesUids.push(key);
    });
    state.articlesLists[query] = { stream, articles: articlesUids, end };
    this.commit('article/addArticles', articles);
  },
  addToArticlesList(state, { articles, queryUid }) {
    Object.entries(articles).forEach(([key, value]) => {
      if (state.articlesLists[queryUid]) {
        state.articlesLists[queryUid].articles.push(key);
        this.commit('article/addArticles', articles);
      }
    });
  },
  setParams(state, { params, query }) {
    state.params[query] = params;
  },
  addQueryUid(state, queryUid) {
    state.queryUids.push(queryUid);
  },
  removeAQueryUid(state, position) {
    state.queryUids.splice(position, 1);
  },
  removeArticlesList(state, uid) {
    Vue.delete(state.articlesLists, uid);
  },
  endStream(state, query) {
    state.articlesLists[query].end = true;
  },
};

export const actions = {
  async fetchArticles({ dispatch, commit, state, getters, rootGetters }, { params, queryUid, length = 10 }) {
    let end = false;
    const currentArticlesList = {};
    const stream = state.dao.list(params?.filters, params?.sorts);
    const reader = streamReaderWithLength(stream, length);

    for await (const article of reader) {
      if (article.done) {
        end = true;
      } else {
        currentArticlesList[article.uid] = article;
      }
    }

    await dispatch('checkArticlesListsLength', queryUid);
    commit('setArticlesList', {
      articles: currentArticlesList,
      query: queryUid,
      stream,
      end,
    });
    return queryUid;
  },
  async fetchArticle({ state, commit }, uid) {
    const article = await state.dao.get(uid);
    commit('addArticle', article);
    return article;
  },
  async checkArticlesListsLength({ dispatch, getters, commit }, queryUid) {
    commit('addQueryUid', queryUid);
    if (getters.queryUids.length > 10) {
      const firstUid = await dispatch('removeQueryUid');
      commit('removeArticlesList', firstUid);
    }
  },
  removeQueryUid({ commit, getters }) {
    const queryUidToRemove = getters.queryUids[0];
    commit('removeAQueryUid');
    return queryUidToRemove;
  },
  async loadMore({ commit, getters }, { queryUid, length = 10 }) {
    const articlesList = getters.articlesList(queryUid);
    const reader = streamReaderWithLength(articlesList.stream, length);
    const articles = {};
    for await (const article of reader) {
      if (article.done) {
        commit('endStream', queryUid);
      } else {
        articles[article.uid] = article;
      }
    }
    if (Object.keys(articles).length > 0) {
      commit('addToArticlesList', {
        articles,
        queryUid,
      });
    }
  },
};
