
  export default {
    name: 'AppDialog',
    model: {
      prop: 'show',
      event: 'change',
    },
    props: {
      show: {
        type: Boolean,
        default: false,
      },
      modalClass: {
        type: String,
        default: '',
      },
      title: {
        type: String,
        default: '',
      },
      confirmBtn: {
        type: String,
        default: 'button.confirm',
      },
      cancelBtn: {
        type: String,
        default: 'button.cancel',
      },
      maxWidth: {
        type: [Number, String],
        default: () => 500,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      btnWidth() {
        return this.$vuetify.breakpoint.smAndUp ? '50%' : '100%';
      },
      showDialog: {
        get() {
          return this.show;
        },
        set(val) {
          return this.$emit('change', val);
        },
      },
    },
    methods: {
      close() {
        this.showDialog = false;
      },
      confirm(value) {
        this.close();
        this.$emit('confirm', value);
      },
    },
  };
