import { render, staticRenderFns } from "./app.vue?vue&type=template&id=81c8144c&"
import script from "./app.vue?vue&type=script&lang=js&"
export * from "./app.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppLoading: require('/srv/deploy/unlover/releases/20241111163531/components/AppLoading.vue').default,AppHeader: require('/srv/deploy/unlover/releases/20241111163531/components/AppHeader.vue').default,BreadcrumbBar: require('/srv/deploy/unlover/releases/20241111163531/components/BreadcrumbBar.vue').default,AppFooter: require('/srv/deploy/unlover/releases/20241111163531/components/AppFooter.vue').default})
