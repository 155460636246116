
  import { mapGetters } from 'vuex';
  import Snackbar from '@opalenet/donatello/lib/snackbar/components/Snackbar';
  import AppHeader from '~/components/AppHeader.vue';
  import AppFooter from '~/components/AppFooter.vue';
  import AppLoading from '~/components/AppLoading.vue';
  import BreadcrumbBar from '~/components/BreadcrumbBar.vue';

  export default {
    name: 'AppLayout',
    components: { Snackbar, AppHeader, AppFooter, AppLoading, BreadcrumbBar },
    middleware: ['auth'],
    data() {
      return {
        showLoader: false,
      };
    },
    head() {
      return {
        title: this.$i18n.t(this.title),
      };
    },
    computed: {
      ...mapGetters(['title']),
    },
    created() {
      this.$nuxt.$on('toggleLoader', () => this.toggleLoader());
    },
    methods: {
      toggleLoader() {
        this.showLoader = !this.showLoader;
      },
    },
  };
