
  import { mapGetters } from 'vuex';

  export default {
    name: 'AppFooter',
    computed: {
      ...mapGetters('menu', ['getMenu']),
    },
    methods: {
      scrollToTop() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      },
    },
  };
