
  export default {
    name: 'AppLoading',
    props: {
      show: {
        default: false,
        type: Boolean,
      },
    },
  };
