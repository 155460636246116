
  import { mapGetters } from 'vuex';

  export default {
    name: 'BreadcrumbBar',
    computed: {
      ...mapGetters('breadcrumb', ['items']),
      show() {
        return this.items.length > 0;
      },
    },
  };
