import { Category as CategoryDAO } from '@opalenet/technodrome/src/dao/daos';
import { streamReader } from '~/utils/content';

export const state = () => ({
  categories: {},
  dao: undefined,
});

export const getters = {
  categories(state) {
    return state.categories;
  },
  category: (state) => (uid) => {
    return state.categories[uid];
  },
};

export const mutations = {
  setDao(state, dao) {
    if (dao instanceof CategoryDAO) {
      state.dao = dao;
    }
  },
  setCategories(state, categories) {
    state.categories = categories;
  },
};

export const actions = {
  async fetchCategories({ commit, state }) {
    const stream = state.dao.list();
    const reader = streamReader(stream);
    const categories = {};
    for await (const category of reader) {
      if (category) {
        categories[category.uid] = category;
      }
    }
    commit('setCategories', categories);
  },
};
