import MenuItem from '@/entities/menuItem';

export const state = () => ({
  menus: {
    publicMobile: [new MenuItem('button.login', '/login', 'button', 'app-bar-btn  mr-0 mr-md-7')],
    memberMobile: [new MenuItem('button.coaching', '/coaching', 'button', 'app-bar-btn  mr-0 mr-md-7')],
    public: [
      new MenuItem('breadcrumbs./articles', '/articles', 'text', 'app-bar-tab px-3'),
      new MenuItem('breadcrumbs./about', '/about', 'text', 'app-bar-tab px-3'),
      new MenuItem('breadcrumbs./contact', '/contact', 'text', 'app-bar-tab px-3'),
      new MenuItem('button.login', '/login', 'button', 'app-bar-btn ml-7 mr-16'),
    ],
    member: [
      new MenuItem('breadcrumbs./articles', '/articles', 'text', 'app-bar-tab px-3'),
      new MenuItem('breadcrumbs./about', '/about', 'text', 'app-bar-tab px-3'),
      new MenuItem('button.coaching', '/coaching', 'button', 'app-bar-btn ml-7 mr-10'),
    ],
    memberBurger: [
      new MenuItem('breadcrumbs./account', '/account', 'text', 'burger-menu-item'),
      new MenuItem('breadcrumbs./contact', '/contact', 'text', 'burger-menu-item'),
      new MenuItem('button.logout', '', 'logout', 'burger-menu-item'),
    ],
    publicDrawer: [
      new MenuItem('breadcrumbs./articles', '/articles', 'text', 'drawer-item'),
      new MenuItem('breadcrumbs./about', '/about', 'text', 'drawer-item'),
      new MenuItem('breadcrumbs./contact', '/contact', 'text', 'drawer-item'),
      new MenuItem('button.login', '/login', 'button', 'drawer-btn'),
    ],
    memberDrawer: [
      new MenuItem('breadcrumbs./articles', '/articles', 'text', 'drawer-item'),
      new MenuItem('breadcrumbs./about', '/about', 'text', 'drawer-item'),
      new MenuItem('breadcrumbs./account', '/account', 'text', 'drawer-item'),
      new MenuItem('breadcrumbs./contact', '/contact', 'text', 'drawer-item'),
      new MenuItem('button.logout', '', 'logout', 'drawer-btn'),
    ],
    footer: [
      new MenuItem('breadcrumbs./contact', '/contact', 'text', 'footer-menu-item'),
      new MenuItem('breadcrumbs./terms-of-service', '/terms-of-service', 'text', 'footer-menu-item'),
    ],
    login: [
      new MenuItem('breadcrumbs./login', '/login', 'button', 'login-btn'),
      new MenuItem('breadcrumbs./registration', '/registration', 'button', 'registration-btn'),
    ],
    profile: [
      new MenuItem('account.menu.account', '/account', 'button', 'profile-btn'),
      new MenuItem('account.menu.subscription', '/account/subscription', 'button', 'subscription-btn'),
    ],
    coaching: [
      new MenuItem('coaching.menu.tab1', '/coaching'),
      new MenuItem('coaching.menu.tab2', '/coaching/tickets'),
    ],
  },
});

export const getters = {
  getMenu: (state) => (key) => {
    return state.menus[key];
  },
};
