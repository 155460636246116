
  import signatureImage from '~/assets/images/logos/404.svg';

  export default {
    name: 'ErrorLayout',
    props: {
      error: {
        type: Object,
        default: () => {
          return {};
        },
      },
    },
    data() {
      return {
        errors: {
          404: {
            text: this.$i18n.t('error.page404.title'),
            title: this.$i18n.t('breadcrumbs./page404'),
            signatureImage,
            errorType: '404',
          },
          500: {
            text: this.$i18n.t('error.page5xx.title'),
            title: this.$i18n.t('breadcrumbs./page5xx'),
            signatureImage: '',
            errorType: '5xx',
          },
        },
        currentError: {
          text: '',
          signatureImage: '',
          errorType: '',
        },
      };
    },
    head() {
      return {
        title: this.currentError.title,
      };
    },
    mounted() {
      this.currentError = this.errors[this.error.statusCode] || this.errors[500];
    },
  };
